import React, { Component } from "react"
import { graphql, navigate } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse, { domToReact } from 'html-react-parser'
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentFormatter from '../components/project-content-formatter'

class ProjectsAZ extends Component {
  render() {
    const StaticPage = this.props.data.wpPage
    let title = ''
    let topCarousel = null
    let content = null
    let contentAdditional = null
    if(StaticPage){
      title = StaticPage.title
      //Fix self-closing spacers, ampersand tags and returns
      StaticPage.content = StaticPage.content
        .replace(/<div (class="kt-block-spacer.*)\/>/gi,'<div $1></div>')
        .replace(/&amp;nbsp;/gi, '&nbsp;')
        .replace(/\t/gi, '')
        .replace(/\n/gi, '')
      contentAdditional = []
      content = []
      let tempContent = StaticPage.content

      let components = []
      if(!parse(tempContent).length) {
        components.push(parse(tempContent))
      }else{
        components = parse(tempContent)
      }
      content = components.map(function(element, i){
        let className = ''
        if(element.props){
          if(element.props.className){
            className = element.props.className
          }
        }
        if(element.type === 'div' && className.includes('gallery')){
          return (null)
        }else if(element.type === 'figure' && className.includes('gallery')){
          return (null)
        }else if(element.type === 'style'){
          return(null)
        }else{
          if(element){
            if(element.type){
              if(!(element.type === 'p' && !element.props.children)){
                contentAdditional.push(element)
              }
            }
          }
          return(
            null
          )
        }
      })
      if(contentAdditional.length > 0){
        contentAdditional = <div className="project-moreinfo content">{contentAdditional}</div>
      }
    }
    const Projects = this.props.data.allWpProject.edges
    let projectsList = Projects.map(function(project, i){
      let source = null
      let mediaItemUrl = null
      let featuredImage = null
      if(project.node){
        if(project.node.featuredImage){
          if(project.node.featuredImage.node){
            if(project.node.featuredImage.node.localFile){
              console.log(project.node.featuredImage.node)
              let source = getImage(project.node.featuredImage.node.localFile?.childImageSharp)
              featuredImage = <GatsbyImage image={source} alt={project.node.title} />
            }else if(project.node.featuredImage.node.sourceUrl){
              featuredImage = <img src={project.node.featuredImage.node.sourceUrl} alt={project.node.title} />
            }
            if(project.node?.featuredImage?.node.mediaItemUrl){
              mediaItemUrl = project.node.featuredImage.node.mediaItemUrl
            }
          }
        }
      }
      return(
        <div className="project" key={i}>
          <Link to={project.node.uri} title={project.node.title}>
            {source ?
            <GatsbyImage image={source} alt={project.node.title} />
            : <img src={mediaItemUrl} />
            }

            <div className="info">
              <div className="project-title" dangerouslySetInnerHTML={{__html: project.node.title}} />
              <div className="project-location">{project.node.location}</div>
            </div>
          </Link>
        </div>
      )
    })
    return (
      <Layout location={this.props.location} className='projects'>
        <SEO title={title} />
        <div className="columns">
          <ContentFormatter content={StaticPage.content} />
          <div className="column">
          <div className="view-header project-buttons section">
          <Link className="projects-button featured active" to="/projects" title="Featured Projects">Featured</Link>
          <Link className="projects-button sort-ascending" to="/projects-a-z" title="Sort Alphabetically">A-Z</Link>
          </div>
          <div className="projects-featured section">
          {projectsList}
          </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ProjectsAZ

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(id: { eq: $id }) {
      title
      content
    }
    allWpProject(sort: {fields: title, order: ASC}, filter: {featured: {eq: true}}) {
      edges {
        node {
          id
          title
          uri
          architect
          location
          shortName
          featuredImage {
            node {
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1200
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
              mediaItemUrl
            }
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
        description
      }
    }
  }
`
