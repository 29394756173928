import React from 'react'
import Parser, { domToReact } from 'html-react-parser'
import Carousel from './carousel'
import Accordion from './accordion'
import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from 'simple-react-lightbox'

const options = {
  replace: (domNode, children) => {
    if(domNode.attribs?.class?.includes('wp-block-gallery') || domNode.attribs?.class?.includes('gallery')){
      let autoPlay = false
      let showArrows = true
      let showIndicators = true
      let fade = true
      let dynamicHeight = true
      // if(domNode.attribs?.class?.includes('auto-off')){
      //   autoPlay = false
      // }
      // if(domNode.attribs?.class?.includes('arrows-off')){
      //   showArrows = false
      // }
      // if(domNode.attribs?.class?.includes('indicators-off')){
      //   showIndicators = false
      // }
      // if(domNode.attribs?.class?.includes('fade')){
      //   fade = true
      // }
      // if(domNode.attribs?.class?.includes('static-height')){
      //   dynamicHeight = false
      // }
      return (<Carousel content={domNode} autoPlay={autoPlay} showArrows={showArrows} showIndicators={showIndicators} fade={fade} dynamicHeight={dynamicHeight} />)
    }else if (domNode.attribs?.class?.includes('lightbox')) {
      return(<SimpleReactLightbox><SRLWrapper options={{
        thumbnails: {
          showThumbnails: true
        }
      }}>{domToReact([domNode])}</SRLWrapper></SimpleReactLightbox>)
    }else if(domNode.attribs?.class?.includes('wp-block-pb-accordion-item')){
      return(<Accordion
        content={domNode}
      />)
    }
  }
}

class ProjectContentFormatter extends React.Component {
  constructor(props) {
    super(props)
    let firstItem = true
    let content = null

    if(Parser(this.props.content, options)){
      content = Parser(this.props.content, options)
      if(content.length){
        content.map((item,i)=>{
          if(typeof item === 'string'){
            return null
          }else{
            if(firstItem){
              firstItem = false
              return item
            }else{
              return (<div key={i}>{item}</div>)
            }
          }
        })
        content = content.shift()
      }
    }
    this.state = {
      hasMounted: false,
      content: content
    }
  }

  onComponentDidMount(){
    this.setState({
      hasMounted: true
    })
  }

  render() {
    return(<ClientOnly>{this.state.content}</ClientOnly>)
  }
}

export default ProjectContentFormatter

function ClientOnly({ children, ...delegated }) {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }
  return (
    <div className="column" {...delegated}>
      {children}
    </div>
  );
}
